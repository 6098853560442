<template>
  <div>
    <h4>Academy Sections</h4>
    <div class="row" v-if="! loading">
      <div class="col-md-4 mb-3" v-for="(section, i) in sections" :key="`section-${i}`">
        <div class="card" style="height: 100%">
          <router-link :to="{ name: 'moderator.academy.show', params: { id: section.id }}">
            <video-thumbnail :video="section.thumbnail" />
          </router-link>
          <div class="card-body">
            <h5><router-link :to="{ name: 'moderator.academy.show', params: { id: section.id }}">#{{ section.sort }} {{ section.title }}</router-link></h5>
            <div class="row">
              <div class="col-md-4">
                <p class="mb-0 text-center"><span class="d-block">Courses: </span>{{ section.courses }}</p>
              </div>
              <div class="col-md-4">
                <p class="mb-0 text-center"><span class="d-block">Weeks: </span>{{ section.weeks }}</p>
              </div>
              <div class="col-md-4">
                <p class="mb-0 text-center"><span class="d-block">Videos: </span>{{ section.videos }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      sections: [],
      loading: true
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get('/api/v1/moderator/academy/sections').then(response => {
        this.sections = response.data.sections
        this.loading = false
      })
    }
  }
}
</script>